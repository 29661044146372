.none-user-select * {
    user-select: none;
}

.vitals {
    position: absolute;
    bottom: 64px;
    right: 16px;
    max-width: 500px;
}
