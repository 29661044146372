.logo-container {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 199;
}

.logo-image {
    width: 120px;
    height: auto; /* Maintain the aspect ratio of the image */
}
