.MuiChip-labelSmall {
    white-space: pre !important;
}

.none-user-select * {
    user-select: none;
}

.info-card {
    position: absolute;
    top: 16px;
    right: 16px;
}

.device-connection-status--active {
    color: green
}
.device-connection-status--disconnected {
    color: red
}
.device-connection-status--inactive {
    color: lightgray;
}
